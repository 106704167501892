import React from 'react'
import { graphql } from 'gatsby'
import { MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import FormResume from '../../../components/formResume'

const SubmitResume = ({ data }) => {
  const post = data.markdownRemark
  return (
    <>
      <Layout>
        <div id="sub-page">
          <Hero
            class="intro-65"
            image={post.frontmatter.image.childImageSharp.gatsbyImageData}
            foreimage={null}
            alt={post.frontmatter.alttext}
            title="Submit your resume"
            subtitle="Careers at UNICOM Global"
            type="root"
          />
        </div>

        <main>
          <section className="bg-gray-light">
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>
              <FormResume />
            </MDBAnimation>
          </section>
        </main>
      </Layout>
    </>
  )
}
export default SubmitResume

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title="Submit your resume"
      description="If you are looking for an innovative environment where employees can make a difference, are rewarded and recognized for their hard work, then join us today."
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {  
    markdownRemark(frontmatter: { name: { eq: "Submit your resume" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
      }
    }
  }
`